/* eslint-disable */
import { AspidaClient } from 'aspida'
import { Methods as Methods0 } from './accounts/admin/verification_request_queue'
import { Methods as Methods1 } from './accounts/admin/verification_request_queue/_id@number'
import { Methods as Methods2 } from './accounts/admin/verification_request_queue/_id@number/check_duplicate'
import { Methods as Methods3 } from './accounts/email'
import { Methods as Methods4 } from './accounts/email/_id@number/sent_confirmation'
import { Methods as Methods5 } from './accounts/leave'
import { Methods as Methods6 } from './accounts/me'
import { Methods as Methods7 } from './accounts/me/bank_account'
import { Methods as Methods8 } from './accounts/me/is_staff'
import { Methods as Methods9 } from './accounts/me/is_superuser'
import { Methods as Methods10 } from './accounts/me/qualifications'
import { Methods as Methods11 } from './accounts/me/qualifications/_id@number'
import { Methods as Methods12 } from './accounts/me/request_verification'
import { Methods as Methods13 } from './accounts/rejection_reason'
import { Methods as Methods14 } from './admin/ad'
import { Methods as Methods15 } from './admin/ad/_id@number'
import { Methods as Methods16 } from './admin/ad/_id@number/download_target_list_csv'
import { Methods as Methods17 } from './admin/ad/ad_logic_classes'
import { Methods as Methods18 } from './admin/ad/ad_slots'
import { Methods as Methods19 } from './admin/adminlog'
import { Methods as Methods20 } from './admin/auth/login'
import { Methods as Methods21 } from './admin/gift_request'
import { Methods as Methods22 } from './admin/gift_request/_id@number'
import { Methods as Methods23 } from './admin/information'
import { Methods as Methods24 } from './admin/information/_id@number'
import { Methods as Methods25 } from './admin/information/_id@number/download_target_list_csv'
import { Methods as Methods26 } from './admin/workers'
import { Methods as Methods27 } from './admin/workers/_id@string/update_memo'
import { Methods as Methods28 } from './admin/workers/bank_account'
import { Methods as Methods29 } from './admin/workers/bank_account/detail/_id@number'
import { Methods as Methods30 } from './admin/workers/detail/_id@string'
import { Methods as Methods31 } from './admin/workers/frozen'
import { Methods as Methods32 } from './admin/workers/leave'
import { Methods as Methods33 } from './admin/workers/qualification'
import { Methods as Methods34 } from './admin/workers/qualification/_id@string'
import { Methods as Methods35 } from './admin/workers/rejection'
import { Methods as Methods36 } from './admin/workers/transfer_request'
import { Methods as Methods37 } from './admin/workers/transfer_request/_id@number'
import { Methods as Methods38 } from './admin/workers/transfer_request/_id@number/approve'
import { Methods as Methods39 } from './admin/workers/transfer_request/_id@number/change_to_error'
import { Methods as Methods40 } from './admin/workers/transfer_request/_id@number/resolve_error'
import { Methods as Methods41 } from './admin/workers/transfer_request/_id@number/transfer_manually'
import { Methods as Methods42 } from './admin/workers/transfer_request/bulk_update_admin_processed'
import { Methods as Methods43 } from './admin/workers/verify'
import { Methods as Methods44 } from './auth/login'
import { Methods as Methods45 } from './auth/password/change'
import { Methods as Methods46 } from './auth/password/reset'
import { Methods as Methods47 } from './auth/password/reset/confirm'
import { Methods as Methods48 } from './auth/registration'
import { Methods as Methods49 } from './auth/registration/other_users'
import { Methods as Methods50 } from './auth/registration/validate_email'
import { Methods as Methods51 } from './auth/registration/verify-email'
import { Methods as Methods52 } from './auth/token/refresh'
import { Methods as Methods53 } from './auth/token/verify'
import { Methods as Methods54 } from './balances'
import { Methods as Methods55 } from './balances/history'
import { Methods as Methods56 } from './balances/request_withdraw'
import { Methods as Methods57 } from './corporations'
import { Methods as Methods58 } from './corporations/_id@number'
import { Methods as Methods59 } from './corporations/accounts/requests'
import { Methods as Methods60 } from './corporations/accounts/requests/_id@number'
import { Methods as Methods61 } from './corporations/accounts/requests/_id@number/accept'
import { Methods as Methods62 } from './corporations/accounts/requests/_id@number/reject'
import { Methods as Methods63 } from './corporations/offices'
import { Methods as Methods64 } from './corporations/offices/_id@number'
import { Methods as Methods65 } from './corporations/sales_responsibilities'
import { Methods as Methods66 } from './corporations/workers'
import { Methods as Methods67 } from './corporations/workers/_id@number'
import { Methods as Methods68 } from './localities'
import { Methods as Methods69 } from './localities/_id@number'
import { Methods as Methods70 } from './notifications'
import { Methods as Methods71 } from './notifications/_id@number'
import { Methods as Methods72 } from './notifications/_id@number/read_notification'
import { Methods as Methods73 } from './orders'
import { Methods as Methods74 } from './orders/_id@number'
import { Methods as Methods75 } from './orders/_id@number/avg_evaluations'
import { Methods as Methods76 } from './orders/admin_applications/_id@number'
import { Methods as Methods77 } from './orders/admin_applications/_id@number/reset_work'
import { Methods as Methods78 } from './orders/admin_applications/remand_attendance_correction'
import { Methods as Methods79 } from './orders/application_messages'
import { Methods as Methods80 } from './orders/applications'
import { Methods as Methods81 } from './orders/applications/_id@number'
import { Methods as Methods82 } from './orders/applications/_id@number/cancel'
import { Methods as Methods83 } from './orders/applications/_id@number/finish_work'
import { Methods as Methods84 } from './orders/applications/_id@number/start_work'
import { Methods as Methods85 } from './orders/applications/can_apply'
import { Methods as Methods86 } from './orders/attendance_corrections/_id@number'
import { Methods as Methods87 } from './orders/attendance_corrections/_id@number/approve'
import { Methods as Methods88 } from './orders/attendance_corrections/_id@number/reject'
import { Methods as Methods89 } from './orders/attendance_corrections/_id@number/submit'
import { Methods as Methods90 } from './orders/cancel_confirmed'
import { Methods as Methods91 } from './orders/cancel_request'
import { Methods as Methods92 } from './orders/cancel_request/_id@number'
import { Methods as Methods93 } from './orders/descriptions'
import { Methods as Methods94 } from './orders/evaluation_by_office'
import { Methods as Methods95 } from './orders/evaluation_by_worker'
import { Methods as Methods96 } from './orders/insufficient_attendances/finish'
import { Methods as Methods97 } from './orders/insufficient_attendances/start'
import { Methods as Methods98 } from './orders/office_to_worker_application_messages'
import { Methods as Methods99 } from './orders/operations'
import { Methods as Methods100 } from './orders/preview_notice_of_employment_url'
import { Methods as Methods101 } from './orders/templates'
import { Methods as Methods102 } from './orders/templates/_id@number'
import { Methods as Methods103 } from './orders/templates/batch_update_attachment_file'
import { Methods as Methods104 } from './orders/templates/download_csv_by_corporation_id'
import { Methods as Methods105 } from './orders/templates/download_csv_by_office_id'
import { Methods as Methods106 } from './orders/templates/summerize_csv'
import { Methods as Methods107 } from './orders/templates/upload_csv'
import { Methods as Methods108 } from './orders/total_avg_evaluations_by_office'
import { Methods as Methods109 } from './orders/total_avg_evaluations_by_worker'
import { Methods as Methods110 } from './orders/total_avg_evaluations_by_worker_list'
import { Methods as Methods111 } from './point_gifts'
import { Methods as Methods112 } from './qualifications'
import { Methods as Methods113 } from './qualifications/rejection_reason'
import { Methods as Methods114 } from './users'
import { Methods as Methods115 } from './users/_id@string'
import { Methods as Methods116 } from './workers'
import { Methods as Methods117 } from './workers/_id@string'
import { Methods as Methods118 } from './workers/_id@string/credit_score'
import { Methods as Methods119 } from './workers/user_experience'
import { Methods as Methods120 } from './workers/work_count/_id@string'

const GET = 'GET'
const POST = 'POST'
const DELETE = 'DELETE'
const PATCH = 'PATCH'
const PATH0 = '/accounts/admin/verification_request_queue/'
const PATH1 = '/'
const PATH2 = '/check_duplicate/'
const PATH3 = '/accounts/email/'
const PATH4 = '/sent_confirmation/'
const PATH5 = '/accounts/leave/'
const PATH6 = '/accounts/me/'
const PATH7 = '/accounts/me/bank_account/'
const PATH8 = '/accounts/me/is_staff/'
const PATH9 = '/accounts/me/is_superuser/'
const PATH10 = '/accounts/me/qualifications/'
const PATH11 = '/accounts/me/request_verification/'
const PATH12 = '/accounts/rejection_reason/'
const PATH13 = '/admin/ad/'
const PATH14 = '/download_target_list_csv/'
const PATH15 = '/admin/ad/ad_logic_classes/'
const PATH16 = '/admin/ad/ad_slots/'
const PATH17 = '/admin/adminlog/'
const PATH18 = '/admin/auth/login/'
const PATH19 = '/admin/gift_request/'
const PATH20 = '/admin/information/'
const PATH21 = '/admin/workers/'
const PATH22 = '/update_memo/'
const PATH23 = '/admin/workers/bank_account/'
const PATH24 = '/admin/workers/bank_account/detail/'
const PATH25 = '/admin/workers/detail/'
const PATH26 = '/admin/workers/frozen/'
const PATH27 = '/admin/workers/leave/'
const PATH28 = '/admin/workers/qualification/'
const PATH29 = '/admin/workers/rejection/'
const PATH30 = '/admin/workers/transfer_request/'
const PATH31 = '/approve/'
const PATH32 = '/change_to_error/'
const PATH33 = '/resolve_error/'
const PATH34 = '/transfer_manually/'
const PATH35 = '/admin/workers/transfer_request/bulk_update_admin_processed/'
const PATH36 = '/admin/workers/verify/'
const PATH37 = '/auth/login/'
const PATH38 = '/auth/password/change/'
const PATH39 = '/auth/password/reset/'
const PATH40 = '/auth/password/reset/confirm/'
const PATH41 = '/auth/registration/'
const PATH42 = '/auth/registration/other_users/'
const PATH43 = '/auth/registration/validate_email/'
const PATH44 = '/auth/registration/verify-email/'
const PATH45 = '/auth/token/refresh/'
const PATH46 = '/auth/token/verify/'
const PATH47 = '/balances/'
const PATH48 = '/balances/history/'
const PATH49 = '/balances/request_withdraw/'
const PATH50 = '/corporations/'
const PATH51 = '/corporations/accounts/requests/'
const PATH52 = '/accept/'
const PATH53 = '/reject/'
const PATH54 = '/corporations/offices/'
const PATH55 = '/corporations/sales_responsibilities/'
const PATH56 = '/corporations/workers/'
const PATH57 = '/localities/'
const PATH58 = '/notifications/'
const PATH59 = '/read_notification/'
const PATH60 = '/orders/'
const PATH61 = '/avg_evaluations/'
const PATH62 = '/orders/admin_applications/'
const PATH63 = '/reset_work/'
const PATH64 = '/orders/admin_applications/remand_attendance_correction/'
const PATH65 = '/orders/application_messages/'
const PATH66 = '/orders/applications/'
const PATH67 = '/cancel/'
const PATH68 = '/finish_work/'
const PATH69 = '/start_work/'
const PATH70 = '/orders/applications/can_apply/'
const PATH71 = '/orders/attendance_corrections/'
const PATH72 = '/submit/'
const PATH73 = '/orders/cancel_confirmed/'
const PATH74 = '/orders/cancel_request/'
const PATH75 = '/orders/descriptions/'
const PATH76 = '/orders/evaluation_by_office/'
const PATH77 = '/orders/evaluation_by_worker/'
const PATH78 = '/orders/insufficient_attendances/finish/'
const PATH79 = '/orders/insufficient_attendances/start/'
const PATH80 = '/orders/office_to_worker_application_messages/'
const PATH81 = '/orders/operations/'
const PATH82 = '/orders/preview_notice_of_employment_url/'
const PATH83 = '/orders/templates/'
const PATH84 = '/orders/templates/batch_update_attachment_file/'
const PATH85 = '/orders/templates/download_csv_by_corporation_id/'
const PATH86 = '/orders/templates/download_csv_by_office_id/'
const PATH87 = '/orders/templates/summerize_csv/'
const PATH88 = '/orders/templates/upload_csv/'
const PATH89 = '/orders/total_avg_evaluations_by_office/'
const PATH90 = '/orders/total_avg_evaluations_by_worker/'
const PATH91 = '/orders/total_avg_evaluations_by_worker_list/'
const PATH92 = '/point_gifts/'
const PATH93 = '/qualifications/'
const PATH94 = '/qualifications/rejection_reason/'
const PATH95 = '/users/'
const PATH96 = '/workers/'
const PATH97 = '/credit_score/'
const PATH98 = '/workers/user_experience/'
const PATH99 = '/workers/work_count/'
const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? '' : baseURL).replace(/\/$/, '')

  return {
    accounts: {
      admin: {
        verification_request_queue: {
          _id: (val0: number) => {
            const prefix0 = `${PATH0}${val0}`

            return {
              check_duplicate: {
                post: (option?: { config?: T }) =>
                  fetch<Methods2['post']['resBody']>(prefix, `${prefix0}${PATH2}`, POST, option).json(),
                $post: (option?: { config?: T }) =>
                  fetch<Methods2['post']['resBody']>(prefix, `${prefix0}${PATH2}`, POST, option).json().then(r => r.body)
              },
              get: (option?: { config?: T }) =>
                fetch<Methods1['get']['resBody']>(prefix, `${prefix0}${PATH1}`, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods1['get']['resBody']>(prefix, `${prefix0}${PATH1}`, GET, option).json().then(r => r.body),
              patch: (option: { body: Methods1['patch']['reqBody'], config?: T }) =>
                fetch<Methods1['patch']['resBody']>(prefix, `${prefix0}${PATH1}`, PATCH, option).json(),
              $patch: (option: { body: Methods1['patch']['reqBody'], config?: T }) =>
                fetch<Methods1['patch']['resBody']>(prefix, `${prefix0}${PATH1}`, PATCH, option).json().then(r => r.body)
            }
          },
          get: (option?: { query?: Methods0['get']['query'], config?: T }) =>
            fetch<Methods0['get']['resBody']>(prefix, PATH0, GET, option).json(),
          $get: (option?: { query?: Methods0['get']['query'], config?: T }) =>
            fetch<Methods0['get']['resBody']>(prefix, PATH0, GET, option).json().then(r => r.body)
        }
      },
      email: {
        _id: (val1: number) => {
          const prefix1 = `${PATH3}${val1}`

          return {
            sent_confirmation: {
              post: (option?: { config?: T }) =>
                fetch<Methods4['post']['resBody']>(prefix, `${prefix1}${PATH4}`, POST, option).json(),
              $post: (option?: { config?: T }) =>
                fetch<Methods4['post']['resBody']>(prefix, `${prefix1}${PATH4}`, POST, option).json().then(r => r.body)
            }
          }
        },
        get: (option?: { query?: Methods3['get']['query'], config?: T }) =>
          fetch<Methods3['get']['resBody']>(prefix, PATH3, GET, option).json(),
        $get: (option?: { query?: Methods3['get']['query'], config?: T }) =>
          fetch<Methods3['get']['resBody']>(prefix, PATH3, GET, option).json().then(r => r.body),
        post: (option: { body: Methods3['post']['reqBody'], config?: T }) =>
          fetch<Methods3['post']['resBody']>(prefix, PATH3, POST, option).json(),
        $post: (option: { body: Methods3['post']['reqBody'], config?: T }) =>
          fetch<Methods3['post']['resBody']>(prefix, PATH3, POST, option).json().then(r => r.body)
      },
      leave: {
        post: (option?: { config?: T }) =>
          fetch<Methods5['post']['resBody']>(prefix, PATH5, POST, option).json(),
        $post: (option?: { config?: T }) =>
          fetch<Methods5['post']['resBody']>(prefix, PATH5, POST, option).json().then(r => r.body)
      },
      me: {
        bank_account: {
          get: (option?: { config?: T }) =>
            fetch<Methods7['get']['resBody']>(prefix, PATH7, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods7['get']['resBody']>(prefix, PATH7, GET, option).json().then(r => r.body),
          post: (option: { body: Methods7['post']['reqBody'], config?: T }) =>
            fetch<Methods7['post']['resBody']>(prefix, PATH7, POST, option).json(),
          $post: (option: { body: Methods7['post']['reqBody'], config?: T }) =>
            fetch<Methods7['post']['resBody']>(prefix, PATH7, POST, option).json().then(r => r.body),
          patch: (option: { body: Methods7['patch']['reqBody'], config?: T }) =>
            fetch<Methods7['patch']['resBody']>(prefix, PATH7, PATCH, option).json(),
          $patch: (option: { body: Methods7['patch']['reqBody'], config?: T }) =>
            fetch<Methods7['patch']['resBody']>(prefix, PATH7, PATCH, option).json().then(r => r.body),
          delete: (option?: { config?: T }) =>
            fetch<void>(prefix, PATH7, DELETE, option).send(),
          $delete: (option?: { config?: T }) =>
            fetch<void>(prefix, PATH7, DELETE, option).send().then(r => r.body)
        },
        is_staff: {
          get: (option?: { config?: T }) =>
            fetch<Methods8['get']['resBody']>(prefix, PATH8, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods8['get']['resBody']>(prefix, PATH8, GET, option).json().then(r => r.body)
        },
        is_superuser: {
          get: (option?: { config?: T }) =>
            fetch<Methods9['get']['resBody']>(prefix, PATH9, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods9['get']['resBody']>(prefix, PATH9, GET, option).json().then(r => r.body)
        },
        qualifications: {
          _id: (val2: number) => {
            const prefix2 = `${PATH10}${val2}`

            return {
              get: (option?: { config?: T }) =>
                fetch<Methods11['get']['resBody']>(prefix, `${prefix2}${PATH1}`, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods11['get']['resBody']>(prefix, `${prefix2}${PATH1}`, GET, option).json().then(r => r.body),
              patch: (option: { body: Methods11['patch']['reqBody'], config?: T }) =>
                fetch<Methods11['patch']['resBody']>(prefix, `${prefix2}${PATH1}`, PATCH, option).json(),
              $patch: (option: { body: Methods11['patch']['reqBody'], config?: T }) =>
                fetch<Methods11['patch']['resBody']>(prefix, `${prefix2}${PATH1}`, PATCH, option).json().then(r => r.body),
              delete: (option?: { config?: T }) =>
                fetch<void>(prefix, `${prefix2}${PATH1}`, DELETE, option).send(),
              $delete: (option?: { config?: T }) =>
                fetch<void>(prefix, `${prefix2}${PATH1}`, DELETE, option).send().then(r => r.body)
            }
          },
          get: (option?: { query?: Methods10['get']['query'], config?: T }) =>
            fetch<Methods10['get']['resBody']>(prefix, PATH10, GET, option).json(),
          $get: (option?: { query?: Methods10['get']['query'], config?: T }) =>
            fetch<Methods10['get']['resBody']>(prefix, PATH10, GET, option).json().then(r => r.body),
          post: (option: { body: Methods10['post']['reqBody'], config?: T }) =>
            fetch<Methods10['post']['resBody']>(prefix, PATH10, POST, option).json(),
          $post: (option: { body: Methods10['post']['reqBody'], config?: T }) =>
            fetch<Methods10['post']['resBody']>(prefix, PATH10, POST, option).json().then(r => r.body)
        },
        request_verification: {
          post: (option?: { config?: T }) =>
            fetch<Methods12['post']['resBody']>(prefix, PATH11, POST, option).json(),
          $post: (option?: { config?: T }) =>
            fetch<Methods12['post']['resBody']>(prefix, PATH11, POST, option).json().then(r => r.body)
        },
        get: (option?: { config?: T }) =>
          fetch<Methods6['get']['resBody']>(prefix, PATH6, GET, option).json(),
        $get: (option?: { config?: T }) =>
          fetch<Methods6['get']['resBody']>(prefix, PATH6, GET, option).json().then(r => r.body),
        patch: (option: { body: Methods6['patch']['reqBody'], config?: T }) =>
          fetch<Methods6['patch']['resBody']>(prefix, PATH6, PATCH, option).json(),
        $patch: (option: { body: Methods6['patch']['reqBody'], config?: T }) =>
          fetch<Methods6['patch']['resBody']>(prefix, PATH6, PATCH, option).json().then(r => r.body)
      },
      rejection_reason: {
        get: (option?: { query?: Methods13['get']['query'], config?: T }) =>
          fetch<Methods13['get']['resBody']>(prefix, PATH12, GET, option).json(),
        $get: (option?: { query?: Methods13['get']['query'], config?: T }) =>
          fetch<Methods13['get']['resBody']>(prefix, PATH12, GET, option).json().then(r => r.body)
      }
    },
    admin: {
      ad: {
        _id: (val3: number) => {
          const prefix3 = `${PATH13}${val3}`

          return {
            download_target_list_csv: {
              get: (option?: { config?: T }) =>
                fetch<Methods16['get']['resBody']>(prefix, `${prefix3}${PATH14}`, GET, option).blob(),
              $get: (option?: { config?: T }) =>
                fetch<Methods16['get']['resBody']>(prefix, `${prefix3}${PATH14}`, GET, option).blob().then(r => r.body)
            },
            get: (option?: { config?: T }) =>
              fetch<Methods15['get']['resBody']>(prefix, `${prefix3}${PATH1}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods15['get']['resBody']>(prefix, `${prefix3}${PATH1}`, GET, option).json().then(r => r.body),
            patch: (option: { body: Methods15['patch']['reqBody'], config?: T }) =>
              fetch<Methods15['patch']['resBody']>(prefix, `${prefix3}${PATH1}`, PATCH, option).json(),
            $patch: (option: { body: Methods15['patch']['reqBody'], config?: T }) =>
              fetch<Methods15['patch']['resBody']>(prefix, `${prefix3}${PATH1}`, PATCH, option).json().then(r => r.body),
            delete: (option?: { config?: T }) =>
              fetch<void>(prefix, `${prefix3}${PATH1}`, DELETE, option).send(),
            $delete: (option?: { config?: T }) =>
              fetch<void>(prefix, `${prefix3}${PATH1}`, DELETE, option).send().then(r => r.body)
          }
        },
        ad_logic_classes: {
          get: (option?: { config?: T }) =>
            fetch<Methods17['get']['resBody']>(prefix, PATH15, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods17['get']['resBody']>(prefix, PATH15, GET, option).json().then(r => r.body)
        },
        ad_slots: {
          get: (option?: { config?: T }) =>
            fetch<Methods18['get']['resBody']>(prefix, PATH16, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods18['get']['resBody']>(prefix, PATH16, GET, option).json().then(r => r.body)
        },
        get: (option?: { query?: Methods14['get']['query'], config?: T }) =>
          fetch<Methods14['get']['resBody']>(prefix, PATH13, GET, option).json(),
        $get: (option?: { query?: Methods14['get']['query'], config?: T }) =>
          fetch<Methods14['get']['resBody']>(prefix, PATH13, GET, option).json().then(r => r.body),
        post: (option: { body: Methods14['post']['reqBody'], config?: T }) =>
          fetch<Methods14['post']['resBody']>(prefix, PATH13, POST, option).json(),
        $post: (option: { body: Methods14['post']['reqBody'], config?: T }) =>
          fetch<Methods14['post']['resBody']>(prefix, PATH13, POST, option).json().then(r => r.body)
      },
      adminlog: {
        get: (option?: { query?: Methods19['get']['query'], config?: T }) =>
          fetch<Methods19['get']['resBody']>(prefix, PATH17, GET, option).json(),
        $get: (option?: { query?: Methods19['get']['query'], config?: T }) =>
          fetch<Methods19['get']['resBody']>(prefix, PATH17, GET, option).json().then(r => r.body)
      },
      auth: {
        login: {
          post: (option: { body: Methods20['post']['reqBody'], config?: T }) =>
            fetch<Methods20['post']['resBody']>(prefix, PATH18, POST, option).json(),
          $post: (option: { body: Methods20['post']['reqBody'], config?: T }) =>
            fetch<Methods20['post']['resBody']>(prefix, PATH18, POST, option).json().then(r => r.body)
        }
      },
      gift_request: {
        _id: (val4: number) => {
          const prefix4 = `${PATH19}${val4}`

          return {
            patch: (option: { body: Methods22['patch']['reqBody'], config?: T }) =>
              fetch<Methods22['patch']['resBody']>(prefix, `${prefix4}${PATH1}`, PATCH, option).json(),
            $patch: (option: { body: Methods22['patch']['reqBody'], config?: T }) =>
              fetch<Methods22['patch']['resBody']>(prefix, `${prefix4}${PATH1}`, PATCH, option).json().then(r => r.body)
          }
        },
        get: (option?: { query?: Methods21['get']['query'], config?: T }) =>
          fetch<Methods21['get']['resBody']>(prefix, PATH19, GET, option).json(),
        $get: (option?: { query?: Methods21['get']['query'], config?: T }) =>
          fetch<Methods21['get']['resBody']>(prefix, PATH19, GET, option).json().then(r => r.body)
      },
      information: {
        _id: (val5: number) => {
          const prefix5 = `${PATH20}${val5}`

          return {
            download_target_list_csv: {
              get: (option?: { config?: T }) =>
                fetch<Methods25['get']['resBody']>(prefix, `${prefix5}${PATH14}`, GET, option).blob(),
              $get: (option?: { config?: T }) =>
                fetch<Methods25['get']['resBody']>(prefix, `${prefix5}${PATH14}`, GET, option).blob().then(r => r.body)
            },
            get: (option?: { config?: T }) =>
              fetch<Methods24['get']['resBody']>(prefix, `${prefix5}${PATH1}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods24['get']['resBody']>(prefix, `${prefix5}${PATH1}`, GET, option).json().then(r => r.body),
            patch: (option: { body: Methods24['patch']['reqBody'], config?: T }) =>
              fetch<Methods24['patch']['resBody']>(prefix, `${prefix5}${PATH1}`, PATCH, option).json(),
            $patch: (option: { body: Methods24['patch']['reqBody'], config?: T }) =>
              fetch<Methods24['patch']['resBody']>(prefix, `${prefix5}${PATH1}`, PATCH, option).json().then(r => r.body),
            delete: (option?: { config?: T }) =>
              fetch<void>(prefix, `${prefix5}${PATH1}`, DELETE, option).send(),
            $delete: (option?: { config?: T }) =>
              fetch<void>(prefix, `${prefix5}${PATH1}`, DELETE, option).send().then(r => r.body)
          }
        },
        get: (option?: { query?: Methods23['get']['query'], config?: T }) =>
          fetch<Methods23['get']['resBody']>(prefix, PATH20, GET, option).json(),
        $get: (option?: { query?: Methods23['get']['query'], config?: T }) =>
          fetch<Methods23['get']['resBody']>(prefix, PATH20, GET, option).json().then(r => r.body),
        post: (option: { body: Methods23['post']['reqBody'], config?: T }) =>
          fetch<Methods23['post']['resBody']>(prefix, PATH20, POST, option, 'FormData').json(),
        $post: (option: { body: Methods23['post']['reqBody'], config?: T }) =>
          fetch<Methods23['post']['resBody']>(prefix, PATH20, POST, option, 'FormData').json().then(r => r.body)
      },
      workers: {
        _id: (val6: string) => {
          const prefix6 = `${PATH21}${val6}`

          return {
            update_memo: {
              patch: (option: { body: Methods27['patch']['reqBody'], config?: T }) =>
                fetch<void>(prefix, `${prefix6}${PATH22}`, PATCH, option).send(),
              $patch: (option: { body: Methods27['patch']['reqBody'], config?: T }) =>
                fetch<void>(prefix, `${prefix6}${PATH22}`, PATCH, option).send().then(r => r.body)
            }
          }
        },
        bank_account: {
          detail: {
            _id: (val7: number) => {
              const prefix7 = `${PATH24}${val7}`

              return {
                get: (option?: { config?: T }) =>
                  fetch<Methods29['get']['resBody']>(prefix, `${prefix7}${PATH1}`, GET, option).json(),
                $get: (option?: { config?: T }) =>
                  fetch<Methods29['get']['resBody']>(prefix, `${prefix7}${PATH1}`, GET, option).json().then(r => r.body),
                patch: (option: { body: Methods29['patch']['reqBody'], config?: T }) =>
                  fetch<Methods29['patch']['resBody']>(prefix, `${prefix7}${PATH1}`, PATCH, option).json(),
                $patch: (option: { body: Methods29['patch']['reqBody'], config?: T }) =>
                  fetch<Methods29['patch']['resBody']>(prefix, `${prefix7}${PATH1}`, PATCH, option).json().then(r => r.body)
              }
            }
          },
          get: (option?: { query?: Methods28['get']['query'], config?: T }) =>
            fetch<Methods28['get']['resBody']>(prefix, PATH23, GET, option).json(),
          $get: (option?: { query?: Methods28['get']['query'], config?: T }) =>
            fetch<Methods28['get']['resBody']>(prefix, PATH23, GET, option).json().then(r => r.body)
        },
        detail: {
          _id: (val8: string) => {
            const prefix8 = `${PATH25}${val8}`

            return {
              get: (option?: { config?: T }) =>
                fetch<Methods30['get']['resBody']>(prefix, `${prefix8}${PATH1}`, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods30['get']['resBody']>(prefix, `${prefix8}${PATH1}`, GET, option).json().then(r => r.body),
              patch: (option: { body: Methods30['patch']['reqBody'], config?: T }) =>
                fetch<Methods30['patch']['resBody']>(prefix, `${prefix8}${PATH1}`, PATCH, option).json(),
              $patch: (option: { body: Methods30['patch']['reqBody'], config?: T }) =>
                fetch<Methods30['patch']['resBody']>(prefix, `${prefix8}${PATH1}`, PATCH, option).json().then(r => r.body)
            }
          }
        },
        frozen: {
          post: (option: { body: Methods31['post']['reqBody'], config?: T }) =>
            fetch<Methods31['post']['resBody']>(prefix, PATH26, POST, option).json(),
          $post: (option: { body: Methods31['post']['reqBody'], config?: T }) =>
            fetch<Methods31['post']['resBody']>(prefix, PATH26, POST, option).json().then(r => r.body)
        },
        leave: {
          post: (option: { body: Methods32['post']['reqBody'], config?: T }) =>
            fetch<Methods32['post']['resBody']>(prefix, PATH27, POST, option).json(),
          $post: (option: { body: Methods32['post']['reqBody'], config?: T }) =>
            fetch<Methods32['post']['resBody']>(prefix, PATH27, POST, option).json().then(r => r.body)
        },
        qualification: {
          _id: (val9: string) => {
            const prefix9 = `${PATH28}${val9}`

            return {
              get: (option?: { config?: T }) =>
                fetch<Methods34['get']['resBody']>(prefix, `${prefix9}${PATH1}`, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods34['get']['resBody']>(prefix, `${prefix9}${PATH1}`, GET, option).json().then(r => r.body),
              patch: (option: { body: Methods34['patch']['reqBody'], config?: T }) =>
                fetch<Methods34['patch']['resBody']>(prefix, `${prefix9}${PATH1}`, PATCH, option).json(),
              $patch: (option: { body: Methods34['patch']['reqBody'], config?: T }) =>
                fetch<Methods34['patch']['resBody']>(prefix, `${prefix9}${PATH1}`, PATCH, option).json().then(r => r.body)
            }
          },
          get: (option?: { query?: Methods33['get']['query'], config?: T }) =>
            fetch<Methods33['get']['resBody']>(prefix, PATH28, GET, option).json(),
          $get: (option?: { query?: Methods33['get']['query'], config?: T }) =>
            fetch<Methods33['get']['resBody']>(prefix, PATH28, GET, option).json().then(r => r.body)
        },
        rejection: {
          post: (option: { body: Methods35['post']['reqBody'], config?: T }) =>
            fetch<Methods35['post']['resBody']>(prefix, PATH29, POST, option).json(),
          $post: (option: { body: Methods35['post']['reqBody'], config?: T }) =>
            fetch<Methods35['post']['resBody']>(prefix, PATH29, POST, option).json().then(r => r.body)
        },
        transfer_request: {
          _id: (val10: number) => {
            const prefix10 = `${PATH30}${val10}`

            return {
              approve: {
                post: (option?: { config?: T }) =>
                  fetch<Methods38['post']['resBody']>(prefix, `${prefix10}${PATH31}`, POST, option).text(),
                $post: (option?: { config?: T }) =>
                  fetch<Methods38['post']['resBody']>(prefix, `${prefix10}${PATH31}`, POST, option).text().then(r => r.body)
              },
              change_to_error: {
                post: (option?: { config?: T }) =>
                  fetch<Methods39['post']['resBody']>(prefix, `${prefix10}${PATH32}`, POST, option).text(),
                $post: (option?: { config?: T }) =>
                  fetch<Methods39['post']['resBody']>(prefix, `${prefix10}${PATH32}`, POST, option).text().then(r => r.body)
              },
              resolve_error: {
                post: (option?: { config?: T }) =>
                  fetch<Methods40['post']['resBody']>(prefix, `${prefix10}${PATH33}`, POST, option).text(),
                $post: (option?: { config?: T }) =>
                  fetch<Methods40['post']['resBody']>(prefix, `${prefix10}${PATH33}`, POST, option).text().then(r => r.body)
              },
              transfer_manually: {
                post: (option: { body: Methods41['post']['reqBody'], config?: T }) =>
                  fetch<Methods41['post']['resBody']>(prefix, `${prefix10}${PATH34}`, POST, option).text(),
                $post: (option: { body: Methods41['post']['reqBody'], config?: T }) =>
                  fetch<Methods41['post']['resBody']>(prefix, `${prefix10}${PATH34}`, POST, option).text().then(r => r.body)
              },
              patch: (option: { body: Methods37['patch']['reqBody'], config?: T }) =>
                fetch<Methods37['patch']['resBody']>(prefix, `${prefix10}${PATH1}`, PATCH, option).json(),
              $patch: (option: { body: Methods37['patch']['reqBody'], config?: T }) =>
                fetch<Methods37['patch']['resBody']>(prefix, `${prefix10}${PATH1}`, PATCH, option).json().then(r => r.body)
            }
          },
          bulk_update_admin_processed: {
            post: (option: { body: Methods42['post']['reqBody'], config?: T }) =>
              fetch<Methods42['post']['resBody']>(prefix, PATH35, POST, option).json(),
            $post: (option: { body: Methods42['post']['reqBody'], config?: T }) =>
              fetch<Methods42['post']['resBody']>(prefix, PATH35, POST, option).json().then(r => r.body)
          },
          get: (option?: { query?: Methods36['get']['query'], config?: T }) =>
            fetch<Methods36['get']['resBody']>(prefix, PATH30, GET, option).json(),
          $get: (option?: { query?: Methods36['get']['query'], config?: T }) =>
            fetch<Methods36['get']['resBody']>(prefix, PATH30, GET, option).json().then(r => r.body)
        },
        verify: {
          post: (option: { body: Methods43['post']['reqBody'], config?: T }) =>
            fetch<Methods43['post']['resBody']>(prefix, PATH36, POST, option).json(),
          $post: (option: { body: Methods43['post']['reqBody'], config?: T }) =>
            fetch<Methods43['post']['resBody']>(prefix, PATH36, POST, option).json().then(r => r.body)
        },
        get: (option?: { query?: Methods26['get']['query'], config?: T }) =>
          fetch<Methods26['get']['resBody']>(prefix, PATH21, GET, option).json(),
        $get: (option?: { query?: Methods26['get']['query'], config?: T }) =>
          fetch<Methods26['get']['resBody']>(prefix, PATH21, GET, option).json().then(r => r.body)
      }
    },
    auth: {
      login: {
        post: (option: { body: Methods44['post']['reqBody'], config?: T }) =>
          fetch<Methods44['post']['resBody']>(prefix, PATH37, POST, option).json(),
        $post: (option: { body: Methods44['post']['reqBody'], config?: T }) =>
          fetch<Methods44['post']['resBody']>(prefix, PATH37, POST, option).json().then(r => r.body)
      },
      password: {
        change: {
          post: (option: { body: Methods45['post']['reqBody'], config?: T }) =>
            fetch<Methods45['post']['resBody']>(prefix, PATH38, POST, option).json(),
          $post: (option: { body: Methods45['post']['reqBody'], config?: T }) =>
            fetch<Methods45['post']['resBody']>(prefix, PATH38, POST, option).json().then(r => r.body)
        },
        reset: {
          confirm: {
            post: (option: { body: Methods47['post']['reqBody'], config?: T }) =>
              fetch<Methods47['post']['resBody']>(prefix, PATH40, POST, option).json(),
            $post: (option: { body: Methods47['post']['reqBody'], config?: T }) =>
              fetch<Methods47['post']['resBody']>(prefix, PATH40, POST, option).json().then(r => r.body)
          },
          post: (option: { body: Methods46['post']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH39, POST, option).send(),
          $post: (option: { body: Methods46['post']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH39, POST, option).send().then(r => r.body)
        }
      },
      registration: {
        other_users: {
          post: (option: { body: Methods49['post']['reqBody'], config?: T }) =>
            fetch<Methods49['post']['resBody']>(prefix, PATH42, POST, option).json(),
          $post: (option: { body: Methods49['post']['reqBody'], config?: T }) =>
            fetch<Methods49['post']['resBody']>(prefix, PATH42, POST, option).json().then(r => r.body)
        },
        validate_email: {
          post: (option: { body: Methods50['post']['reqBody'], config?: T }) =>
            fetch<Methods50['post']['resBody']>(prefix, PATH43, POST, option).json(),
          $post: (option: { body: Methods50['post']['reqBody'], config?: T }) =>
            fetch<Methods50['post']['resBody']>(prefix, PATH43, POST, option).json().then(r => r.body)
        },
        verify_email: {
          post: (option: { body: Methods51['post']['reqBody'], config?: T }) =>
            fetch<Methods51['post']['resBody']>(prefix, PATH44, POST, option).json(),
          $post: (option: { body: Methods51['post']['reqBody'], config?: T }) =>
            fetch<Methods51['post']['resBody']>(prefix, PATH44, POST, option).json().then(r => r.body)
        },
        post: (option: { body: Methods48['post']['reqBody'], config?: T }) =>
          fetch<Methods48['post']['resBody']>(prefix, PATH41, POST, option).json(),
        $post: (option: { body: Methods48['post']['reqBody'], config?: T }) =>
          fetch<Methods48['post']['resBody']>(prefix, PATH41, POST, option).json().then(r => r.body)
      },
      token: {
        refresh: {
          post: (option: { body: Methods52['post']['reqBody'], config?: T }) =>
            fetch<Methods52['post']['resBody']>(prefix, PATH45, POST, option).json(),
          $post: (option: { body: Methods52['post']['reqBody'], config?: T }) =>
            fetch<Methods52['post']['resBody']>(prefix, PATH45, POST, option).json().then(r => r.body)
        },
        verify: {
          post: (option: { body: Methods53['post']['reqBody'], config?: T }) =>
            fetch<Methods53['post']['resBody']>(prefix, PATH46, POST, option).json(),
          $post: (option: { body: Methods53['post']['reqBody'], config?: T }) =>
            fetch<Methods53['post']['resBody']>(prefix, PATH46, POST, option).json().then(r => r.body)
        }
      }
    },
    balances: {
      history: {
        get: (option?: { query?: Methods55['get']['query'], config?: T }) =>
          fetch<Methods55['get']['resBody']>(prefix, PATH48, GET, option).json(),
        $get: (option?: { query?: Methods55['get']['query'], config?: T }) =>
          fetch<Methods55['get']['resBody']>(prefix, PATH48, GET, option).json().then(r => r.body)
      },
      request_withdraw: {
        post: (option: { body: Methods56['post']['reqBody'], config?: T }) =>
          fetch<Methods56['post']['resBody']>(prefix, PATH49, POST, option).json(),
        $post: (option: { body: Methods56['post']['reqBody'], config?: T }) =>
          fetch<Methods56['post']['resBody']>(prefix, PATH49, POST, option).json().then(r => r.body)
      },
      get: (option?: { config?: T }) =>
        fetch<Methods54['get']['resBody']>(prefix, PATH47, GET, option).json(),
      $get: (option?: { config?: T }) =>
        fetch<Methods54['get']['resBody']>(prefix, PATH47, GET, option).json().then(r => r.body)
    },
    corporations: {
      _id: (val11: number) => {
        const prefix11 = `${PATH50}${val11}`

        return {
          get: (option?: { config?: T }) =>
            fetch<Methods58['get']['resBody']>(prefix, `${prefix11}${PATH1}`, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods58['get']['resBody']>(prefix, `${prefix11}${PATH1}`, GET, option).json().then(r => r.body),
          post: (option: { body: Methods58['post']['reqBody'], config?: T }) =>
            fetch<Methods58['post']['resBody']>(prefix, `${prefix11}${PATH1}`, POST, option).json(),
          $post: (option: { body: Methods58['post']['reqBody'], config?: T }) =>
            fetch<Methods58['post']['resBody']>(prefix, `${prefix11}${PATH1}`, POST, option).json().then(r => r.body)
        }
      },
      accounts: {
        requests: {
          _id: (val12: number) => {
            const prefix12 = `${PATH51}${val12}`

            return {
              accept: {
                post: (option: { body: Methods61['post']['reqBody'], config?: T }) =>
                  fetch<Methods61['post']['resBody']>(prefix, `${prefix12}${PATH52}`, POST, option).json(),
                $post: (option: { body: Methods61['post']['reqBody'], config?: T }) =>
                  fetch<Methods61['post']['resBody']>(prefix, `${prefix12}${PATH52}`, POST, option).json().then(r => r.body)
              },
              reject: {
                post: (option: { body: Methods62['post']['reqBody'], config?: T }) =>
                  fetch<Methods62['post']['resBody']>(prefix, `${prefix12}${PATH53}`, POST, option).json(),
                $post: (option: { body: Methods62['post']['reqBody'], config?: T }) =>
                  fetch<Methods62['post']['resBody']>(prefix, `${prefix12}${PATH53}`, POST, option).json().then(r => r.body)
              },
              get: (option?: { config?: T }) =>
                fetch<Methods60['get']['resBody']>(prefix, `${prefix12}${PATH1}`, GET, option).json(),
              $get: (option?: { config?: T }) =>
                fetch<Methods60['get']['resBody']>(prefix, `${prefix12}${PATH1}`, GET, option).json().then(r => r.body),
              post: (option: { body: Methods60['post']['reqBody'], config?: T }) =>
                fetch<Methods60['post']['resBody']>(prefix, `${prefix12}${PATH1}`, POST, option).json(),
              $post: (option: { body: Methods60['post']['reqBody'], config?: T }) =>
                fetch<Methods60['post']['resBody']>(prefix, `${prefix12}${PATH1}`, POST, option).json().then(r => r.body),
              patch: (option: { body: Methods60['patch']['reqBody'], config?: T }) =>
                fetch<Methods60['patch']['resBody']>(prefix, `${prefix12}${PATH1}`, PATCH, option).json(),
              $patch: (option: { body: Methods60['patch']['reqBody'], config?: T }) =>
                fetch<Methods60['patch']['resBody']>(prefix, `${prefix12}${PATH1}`, PATCH, option).json().then(r => r.body)
            }
          },
          get: (option?: { query?: Methods59['get']['query'], config?: T }) =>
            fetch<Methods59['get']['resBody']>(prefix, PATH51, GET, option).json(),
          $get: (option?: { query?: Methods59['get']['query'], config?: T }) =>
            fetch<Methods59['get']['resBody']>(prefix, PATH51, GET, option).json().then(r => r.body),
          post: (option: { body: Methods59['post']['reqBody'], config?: T }) =>
            fetch<Methods59['post']['resBody']>(prefix, PATH51, POST, option).json(),
          $post: (option: { body: Methods59['post']['reqBody'], config?: T }) =>
            fetch<Methods59['post']['resBody']>(prefix, PATH51, POST, option).json().then(r => r.body)
        }
      },
      offices: {
        _id: (val13: number) => {
          const prefix13 = `${PATH54}${val13}`

          return {
            get: (option?: { config?: T }) =>
              fetch<Methods64['get']['resBody']>(prefix, `${prefix13}${PATH1}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods64['get']['resBody']>(prefix, `${prefix13}${PATH1}`, GET, option).json().then(r => r.body)
          }
        },
        get: (option?: { query?: Methods63['get']['query'], config?: T }) =>
          fetch<Methods63['get']['resBody']>(prefix, PATH54, GET, option).json(),
        $get: (option?: { query?: Methods63['get']['query'], config?: T }) =>
          fetch<Methods63['get']['resBody']>(prefix, PATH54, GET, option).json().then(r => r.body)
      },
      sales_responsibilities: {
        get: (option?: { config?: T }) =>
          fetch<Methods65['get']['resBody']>(prefix, PATH55, GET, option).json(),
        $get: (option?: { config?: T }) =>
          fetch<Methods65['get']['resBody']>(prefix, PATH55, GET, option).json().then(r => r.body)
      },
      workers: {
        _id: (val14: number) => {
          const prefix14 = `${PATH56}${val14}`

          return {
            patch: (option: { body: Methods67['patch']['reqBody'], config?: T }) =>
              fetch<void>(prefix, `${prefix14}${PATH1}`, PATCH, option).send(),
            $patch: (option: { body: Methods67['patch']['reqBody'], config?: T }) =>
              fetch<void>(prefix, `${prefix14}${PATH1}`, PATCH, option).send().then(r => r.body)
          }
        },
        get: (option?: { query?: Methods66['get']['query'], config?: T }) =>
          fetch<Methods66['get']['resBody']>(prefix, PATH56, GET, option).json(),
        $get: (option?: { query?: Methods66['get']['query'], config?: T }) =>
          fetch<Methods66['get']['resBody']>(prefix, PATH56, GET, option).json().then(r => r.body)
      },
      get: (option?: { query?: Methods57['get']['query'], config?: T }) =>
        fetch<Methods57['get']['resBody']>(prefix, PATH50, GET, option).json(),
      $get: (option?: { query?: Methods57['get']['query'], config?: T }) =>
        fetch<Methods57['get']['resBody']>(prefix, PATH50, GET, option).json().then(r => r.body),
      post: (option: { body: Methods57['post']['reqBody'], config?: T }) =>
        fetch<Methods57['post']['resBody']>(prefix, PATH50, POST, option).json(),
      $post: (option: { body: Methods57['post']['reqBody'], config?: T }) =>
        fetch<Methods57['post']['resBody']>(prefix, PATH50, POST, option).json().then(r => r.body)
    },
    localities: {
      _id: (val15: number) => {
        const prefix15 = `${PATH57}${val15}`

        return {
          get: (option?: { config?: T }) =>
            fetch<Methods69['get']['resBody']>(prefix, `${prefix15}${PATH1}`, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods69['get']['resBody']>(prefix, `${prefix15}${PATH1}`, GET, option).json().then(r => r.body)
        }
      },
      get: (option?: { query?: Methods68['get']['query'], config?: T }) =>
        fetch<Methods68['get']['resBody']>(prefix, PATH57, GET, option).json(),
      $get: (option?: { query?: Methods68['get']['query'], config?: T }) =>
        fetch<Methods68['get']['resBody']>(prefix, PATH57, GET, option).json().then(r => r.body)
    },
    notifications: {
      _id: (val16: number) => {
        const prefix16 = `${PATH58}${val16}`

        return {
          read_notification: {
            patch: (option?: { config?: T }) =>
              fetch<Methods72['patch']['resBody']>(prefix, `${prefix16}${PATH59}`, PATCH, option).json(),
            $patch: (option?: { config?: T }) =>
              fetch<Methods72['patch']['resBody']>(prefix, `${prefix16}${PATH59}`, PATCH, option).json().then(r => r.body)
          },
          get: (option?: { config?: T }) =>
            fetch<Methods71['get']['resBody']>(prefix, `${prefix16}${PATH1}`, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods71['get']['resBody']>(prefix, `${prefix16}${PATH1}`, GET, option).json().then(r => r.body)
        }
      },
      get: (option?: { query?: Methods70['get']['query'], config?: T }) =>
        fetch<Methods70['get']['resBody']>(prefix, PATH58, GET, option).json(),
      $get: (option?: { query?: Methods70['get']['query'], config?: T }) =>
        fetch<Methods70['get']['resBody']>(prefix, PATH58, GET, option).json().then(r => r.body)
    },
    orders: {
      _id: (val17: number) => {
        const prefix17 = `${PATH60}${val17}`

        return {
          avg_evaluations: {
            get: (option?: { config?: T }) =>
              fetch<Methods75['get']['resBody']>(prefix, `${prefix17}${PATH61}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods75['get']['resBody']>(prefix, `${prefix17}${PATH61}`, GET, option).json().then(r => r.body)
          },
          get: (option?: { config?: T }) =>
            fetch<Methods74['get']['resBody']>(prefix, `${prefix17}${PATH1}`, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods74['get']['resBody']>(prefix, `${prefix17}${PATH1}`, GET, option).json().then(r => r.body),
          patch: (option: { body: Methods74['patch']['reqBody'], config?: T }) =>
            fetch<Methods74['patch']['resBody']>(prefix, `${prefix17}${PATH1}`, PATCH, option).json(),
          $patch: (option: { body: Methods74['patch']['reqBody'], config?: T }) =>
            fetch<Methods74['patch']['resBody']>(prefix, `${prefix17}${PATH1}`, PATCH, option).json().then(r => r.body)
        }
      },
      admin_applications: {
        _id: (val18: number) => {
          const prefix18 = `${PATH62}${val18}`

          return {
            reset_work: {
              post: (option?: { config?: T }) =>
                fetch<Methods77['post']['resBody']>(prefix, `${prefix18}${PATH63}`, POST, option).json(),
              $post: (option?: { config?: T }) =>
                fetch<Methods77['post']['resBody']>(prefix, `${prefix18}${PATH63}`, POST, option).json().then(r => r.body)
            },
            get: (option?: { config?: T }) =>
              fetch<Methods76['get']['resBody']>(prefix, `${prefix18}${PATH1}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods76['get']['resBody']>(prefix, `${prefix18}${PATH1}`, GET, option).json().then(r => r.body)
          }
        },
        remand_attendance_correction: {
          post: (option: { body: Methods78['post']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH64, POST, option).send(),
          $post: (option: { body: Methods78['post']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH64, POST, option).send().then(r => r.body)
        }
      },
      application_messages: {
        get: (option?: { query?: Methods79['get']['query'], config?: T }) =>
          fetch<Methods79['get']['resBody']>(prefix, PATH65, GET, option).json(),
        $get: (option?: { query?: Methods79['get']['query'], config?: T }) =>
          fetch<Methods79['get']['resBody']>(prefix, PATH65, GET, option).json().then(r => r.body),
        post: (option: { body: Methods79['post']['reqBody'], config?: T }) =>
          fetch<Methods79['post']['resBody']>(prefix, PATH65, POST, option).json(),
        $post: (option: { body: Methods79['post']['reqBody'], config?: T }) =>
          fetch<Methods79['post']['resBody']>(prefix, PATH65, POST, option).json().then(r => r.body)
      },
      applications: {
        _id: (val19: number) => {
          const prefix19 = `${PATH66}${val19}`

          return {
            cancel: {
              post: (option?: { config?: T }) =>
                fetch<Methods82['post']['resBody']>(prefix, `${prefix19}${PATH67}`, POST, option).json(),
              $post: (option?: { config?: T }) =>
                fetch<Methods82['post']['resBody']>(prefix, `${prefix19}${PATH67}`, POST, option).json().then(r => r.body)
            },
            finish_work: {
              post: (option: { body: Methods83['post']['reqBody'], config?: T }) =>
                fetch<Methods83['post']['resBody']>(prefix, `${prefix19}${PATH68}`, POST, option).json(),
              $post: (option: { body: Methods83['post']['reqBody'], config?: T }) =>
                fetch<Methods83['post']['resBody']>(prefix, `${prefix19}${PATH68}`, POST, option).json().then(r => r.body)
            },
            start_work: {
              post: (option: { body: Methods84['post']['reqBody'], config?: T }) =>
                fetch<Methods84['post']['resBody']>(prefix, `${prefix19}${PATH69}`, POST, option).json(),
              $post: (option: { body: Methods84['post']['reqBody'], config?: T }) =>
                fetch<Methods84['post']['resBody']>(prefix, `${prefix19}${PATH69}`, POST, option).json().then(r => r.body)
            },
            get: (option?: { config?: T }) =>
              fetch<Methods81['get']['resBody']>(prefix, `${prefix19}${PATH1}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods81['get']['resBody']>(prefix, `${prefix19}${PATH1}`, GET, option).json().then(r => r.body),
            patch: (option: { body: Methods81['patch']['reqBody'], config?: T }) =>
              fetch<Methods81['patch']['resBody']>(prefix, `${prefix19}${PATH1}`, PATCH, option).json(),
            $patch: (option: { body: Methods81['patch']['reqBody'], config?: T }) =>
              fetch<Methods81['patch']['resBody']>(prefix, `${prefix19}${PATH1}`, PATCH, option).json().then(r => r.body)
          }
        },
        can_apply: {
          post: (option: { body: Methods85['post']['reqBody'], config?: T }) =>
            fetch<Methods85['post']['resBody']>(prefix, PATH70, POST, option).json(),
          $post: (option: { body: Methods85['post']['reqBody'], config?: T }) =>
            fetch<Methods85['post']['resBody']>(prefix, PATH70, POST, option).json().then(r => r.body)
        },
        get: (option?: { query?: Methods80['get']['query'], config?: T }) =>
          fetch<Methods80['get']['resBody']>(prefix, PATH66, GET, option).json(),
        $get: (option?: { query?: Methods80['get']['query'], config?: T }) =>
          fetch<Methods80['get']['resBody']>(prefix, PATH66, GET, option).json().then(r => r.body),
        post: (option: { body: Methods80['post']['reqBody'], config?: T }) =>
          fetch<Methods80['post']['resBody']>(prefix, PATH66, POST, option).json(),
        $post: (option: { body: Methods80['post']['reqBody'], config?: T }) =>
          fetch<Methods80['post']['resBody']>(prefix, PATH66, POST, option).json().then(r => r.body)
      },
      attendance_corrections: {
        _id: (val20: number) => {
          const prefix20 = `${PATH71}${val20}`

          return {
            approve: {
              post: (option: { body: Methods87['post']['reqBody'], config?: T }) =>
                fetch<Methods87['post']['resBody']>(prefix, `${prefix20}${PATH31}`, POST, option).json(),
              $post: (option: { body: Methods87['post']['reqBody'], config?: T }) =>
                fetch<Methods87['post']['resBody']>(prefix, `${prefix20}${PATH31}`, POST, option).json().then(r => r.body)
            },
            reject: {
              post: (option: { body: Methods88['post']['reqBody'], config?: T }) =>
                fetch<Methods88['post']['resBody']>(prefix, `${prefix20}${PATH53}`, POST, option).json(),
              $post: (option: { body: Methods88['post']['reqBody'], config?: T }) =>
                fetch<Methods88['post']['resBody']>(prefix, `${prefix20}${PATH53}`, POST, option).json().then(r => r.body)
            },
            submit: {
              post: (option: { body: Methods89['post']['reqBody'], config?: T }) =>
                fetch<Methods89['post']['resBody']>(prefix, `${prefix20}${PATH72}`, POST, option).json(),
              $post: (option: { body: Methods89['post']['reqBody'], config?: T }) =>
                fetch<Methods89['post']['resBody']>(prefix, `${prefix20}${PATH72}`, POST, option).json().then(r => r.body)
            },
            get: (option?: { config?: T }) =>
              fetch<Methods86['get']['resBody']>(prefix, `${prefix20}${PATH1}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods86['get']['resBody']>(prefix, `${prefix20}${PATH1}`, GET, option).json().then(r => r.body)
          }
        }
      },
      cancel_confirmed: {
        post: (option: { body: Methods90['post']['reqBody'], config?: T }) =>
          fetch<void>(prefix, PATH73, POST, option).send(),
        $post: (option: { body: Methods90['post']['reqBody'], config?: T }) =>
          fetch<void>(prefix, PATH73, POST, option).send().then(r => r.body)
      },
      cancel_request: {
        _id: (val21: number) => {
          const prefix21 = `${PATH74}${val21}`

          return {
            get: (option?: { config?: T }) =>
              fetch<Methods92['get']['resBody']>(prefix, `${prefix21}${PATH1}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods92['get']['resBody']>(prefix, `${prefix21}${PATH1}`, GET, option).json().then(r => r.body),
            patch: (option: { body: Methods92['patch']['reqBody'], config?: T }) =>
              fetch<Methods92['patch']['resBody']>(prefix, `${prefix21}${PATH1}`, PATCH, option).json(),
            $patch: (option: { body: Methods92['patch']['reqBody'], config?: T }) =>
              fetch<Methods92['patch']['resBody']>(prefix, `${prefix21}${PATH1}`, PATCH, option).json().then(r => r.body)
          }
        },
        get: (option?: { query?: Methods91['get']['query'], config?: T }) =>
          fetch<Methods91['get']['resBody']>(prefix, PATH74, GET, option).json(),
        $get: (option?: { query?: Methods91['get']['query'], config?: T }) =>
          fetch<Methods91['get']['resBody']>(prefix, PATH74, GET, option).json().then(r => r.body),
        post: (option: { body: Methods91['post']['reqBody'], config?: T }) =>
          fetch<Methods91['post']['resBody']>(prefix, PATH74, POST, option).json(),
        $post: (option: { body: Methods91['post']['reqBody'], config?: T }) =>
          fetch<Methods91['post']['resBody']>(prefix, PATH74, POST, option).json().then(r => r.body)
      },
      descriptions: {
        get: (option?: { query?: Methods93['get']['query'], config?: T }) =>
          fetch<Methods93['get']['resBody']>(prefix, PATH75, GET, option).json(),
        $get: (option?: { query?: Methods93['get']['query'], config?: T }) =>
          fetch<Methods93['get']['resBody']>(prefix, PATH75, GET, option).json().then(r => r.body)
      },
      evaluation_by_office: {
        post: (option: { body: Methods94['post']['reqBody'], config?: T }) =>
          fetch<Methods94['post']['resBody']>(prefix, PATH76, POST, option).json(),
        $post: (option: { body: Methods94['post']['reqBody'], config?: T }) =>
          fetch<Methods94['post']['resBody']>(prefix, PATH76, POST, option).json().then(r => r.body)
      },
      evaluation_by_worker: {
        get: (option?: { query?: Methods95['get']['query'], config?: T }) =>
          fetch<Methods95['get']['resBody']>(prefix, PATH77, GET, option).json(),
        $get: (option?: { query?: Methods95['get']['query'], config?: T }) =>
          fetch<Methods95['get']['resBody']>(prefix, PATH77, GET, option).json().then(r => r.body),
        post: (option: { body: Methods95['post']['reqBody'], config?: T }) =>
          fetch<Methods95['post']['resBody']>(prefix, PATH77, POST, option).json(),
        $post: (option: { body: Methods95['post']['reqBody'], config?: T }) =>
          fetch<Methods95['post']['resBody']>(prefix, PATH77, POST, option).json().then(r => r.body)
      },
      insufficient_attendances: {
        finish: {
          get: (option?: { query?: Methods96['get']['query'], config?: T }) =>
            fetch<Methods96['get']['resBody']>(prefix, PATH78, GET, option).json(),
          $get: (option?: { query?: Methods96['get']['query'], config?: T }) =>
            fetch<Methods96['get']['resBody']>(prefix, PATH78, GET, option).json().then(r => r.body)
        },
        start: {
          get: (option?: { query?: Methods97['get']['query'], config?: T }) =>
            fetch<Methods97['get']['resBody']>(prefix, PATH79, GET, option).json(),
          $get: (option?: { query?: Methods97['get']['query'], config?: T }) =>
            fetch<Methods97['get']['resBody']>(prefix, PATH79, GET, option).json().then(r => r.body)
        }
      },
      office_to_worker_application_messages: {
        get: (option?: { query?: Methods98['get']['query'], config?: T }) =>
          fetch<Methods98['get']['resBody']>(prefix, PATH80, GET, option).json(),
        $get: (option?: { query?: Methods98['get']['query'], config?: T }) =>
          fetch<Methods98['get']['resBody']>(prefix, PATH80, GET, option).json().then(r => r.body),
        post: (option: { body: Methods98['post']['reqBody'], config?: T }) =>
          fetch<Methods98['post']['resBody']>(prefix, PATH80, POST, option).json(),
        $post: (option: { body: Methods98['post']['reqBody'], config?: T }) =>
          fetch<Methods98['post']['resBody']>(prefix, PATH80, POST, option).json().then(r => r.body)
      },
      operations: {
        get: (option?: { query?: Methods99['get']['query'], config?: T }) =>
          fetch<Methods99['get']['resBody']>(prefix, PATH81, GET, option).json(),
        $get: (option?: { query?: Methods99['get']['query'], config?: T }) =>
          fetch<Methods99['get']['resBody']>(prefix, PATH81, GET, option).json().then(r => r.body)
      },
      preview_notice_of_employment_url: {
        post: (option: { body: Methods100['post']['reqBody'], config?: T }) =>
          fetch<Methods100['post']['resBody']>(prefix, PATH82, POST, option).json(),
        $post: (option: { body: Methods100['post']['reqBody'], config?: T }) =>
          fetch<Methods100['post']['resBody']>(prefix, PATH82, POST, option).json().then(r => r.body)
      },
      templates: {
        _id: (val22: number) => {
          const prefix22 = `${PATH83}${val22}`

          return {
            get: (option?: { config?: T }) =>
              fetch<Methods102['get']['resBody']>(prefix, `${prefix22}${PATH1}`, GET, option).json(),
            $get: (option?: { config?: T }) =>
              fetch<Methods102['get']['resBody']>(prefix, `${prefix22}${PATH1}`, GET, option).json().then(r => r.body)
          }
        },
        batch_update_attachment_file: {
          post: (option: { body: Methods103['post']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH84, POST, option).send(),
          $post: (option: { body: Methods103['post']['reqBody'], config?: T }) =>
            fetch<void>(prefix, PATH84, POST, option).send().then(r => r.body)
        },
        download_csv_by_corporation_id: {
          get: (option: { query: Methods104['get']['query'], config?: T }) =>
            fetch<Methods104['get']['resBody']>(prefix, PATH85, GET, option).blob(),
          $get: (option: { query: Methods104['get']['query'], config?: T }) =>
            fetch<Methods104['get']['resBody']>(prefix, PATH85, GET, option).blob().then(r => r.body)
        },
        download_csv_by_office_id: {
          get: (option: { query: Methods105['get']['query'], config?: T }) =>
            fetch<Methods105['get']['resBody']>(prefix, PATH86, GET, option).blob(),
          $get: (option: { query: Methods105['get']['query'], config?: T }) =>
            fetch<Methods105['get']['resBody']>(prefix, PATH86, GET, option).blob().then(r => r.body)
        },
        summerize_csv: {
          post: (option: { body: Methods106['post']['reqBody'], config?: T }) =>
            fetch<Methods106['post']['resBody']>(prefix, PATH87, POST, option).json(),
          $post: (option: { body: Methods106['post']['reqBody'], config?: T }) =>
            fetch<Methods106['post']['resBody']>(prefix, PATH87, POST, option).json().then(r => r.body)
        },
        upload_csv: {
          post: (option: { body: Methods107['post']['reqBody'], config?: T }) =>
            fetch<Methods107['post']['resBody']>(prefix, PATH88, POST, option).json(),
          $post: (option: { body: Methods107['post']['reqBody'], config?: T }) =>
            fetch<Methods107['post']['resBody']>(prefix, PATH88, POST, option).json().then(r => r.body)
        },
        get: (option?: { query?: Methods101['get']['query'], config?: T }) =>
          fetch<Methods101['get']['resBody']>(prefix, PATH83, GET, option).json(),
        $get: (option?: { query?: Methods101['get']['query'], config?: T }) =>
          fetch<Methods101['get']['resBody']>(prefix, PATH83, GET, option).json().then(r => r.body)
      },
      total_avg_evaluations_by_office: {
        get: (option: { query: Methods108['get']['query'], config?: T }) =>
          fetch<Methods108['get']['resBody']>(prefix, PATH89, GET, option).json(),
        $get: (option: { query: Methods108['get']['query'], config?: T }) =>
          fetch<Methods108['get']['resBody']>(prefix, PATH89, GET, option).json().then(r => r.body)
      },
      total_avg_evaluations_by_worker: {
        get: (option: { query: Methods109['get']['query'], config?: T }) =>
          fetch<Methods109['get']['resBody']>(prefix, PATH90, GET, option).json(),
        $get: (option: { query: Methods109['get']['query'], config?: T }) =>
          fetch<Methods109['get']['resBody']>(prefix, PATH90, GET, option).json().then(r => r.body)
      },
      total_avg_evaluations_by_worker_list: {
        get: (option: { query: Methods110['get']['query'], config?: T }) =>
          fetch<Methods110['get']['resBody']>(prefix, PATH91, GET, option).json(),
        $get: (option: { query: Methods110['get']['query'], config?: T }) =>
          fetch<Methods110['get']['resBody']>(prefix, PATH91, GET, option).json().then(r => r.body)
      },
      get: (option?: { query?: Methods73['get']['query'], config?: T }) =>
        fetch<Methods73['get']['resBody']>(prefix, PATH60, GET, option).json(),
      $get: (option?: { query?: Methods73['get']['query'], config?: T }) =>
        fetch<Methods73['get']['resBody']>(prefix, PATH60, GET, option).json().then(r => r.body),
      post: (option: { body: Methods73['post']['reqBody'], config?: T }) =>
        fetch<Methods73['post']['resBody']>(prefix, PATH60, POST, option).json(),
      $post: (option: { body: Methods73['post']['reqBody'], config?: T }) =>
        fetch<Methods73['post']['resBody']>(prefix, PATH60, POST, option).json().then(r => r.body)
    },
    point_gifts: {
      get: (option?: { query?: Methods111['get']['query'], config?: T }) =>
        fetch<Methods111['get']['resBody']>(prefix, PATH92, GET, option).json(),
      $get: (option?: { query?: Methods111['get']['query'], config?: T }) =>
        fetch<Methods111['get']['resBody']>(prefix, PATH92, GET, option).json().then(r => r.body)
    },
    qualifications: {
      rejection_reason: {
        get: (option?: { query?: Methods113['get']['query'], config?: T }) =>
          fetch<Methods113['get']['resBody']>(prefix, PATH94, GET, option).json(),
        $get: (option?: { query?: Methods113['get']['query'], config?: T }) =>
          fetch<Methods113['get']['resBody']>(prefix, PATH94, GET, option).json().then(r => r.body)
      },
      get: (option?: { query?: Methods112['get']['query'], config?: T }) =>
        fetch<Methods112['get']['resBody']>(prefix, PATH93, GET, option).json(),
      $get: (option?: { query?: Methods112['get']['query'], config?: T }) =>
        fetch<Methods112['get']['resBody']>(prefix, PATH93, GET, option).json().then(r => r.body)
    },
    users: {
      _id: (val23: string) => {
        const prefix23 = `${PATH95}${val23}`

        return {
          get: (option?: { config?: T }) =>
            fetch<Methods115['get']['resBody']>(prefix, `${prefix23}${PATH1}`, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods115['get']['resBody']>(prefix, `${prefix23}${PATH1}`, GET, option).json().then(r => r.body),
          patch: (option: { body: Methods115['patch']['reqBody'], config?: T }) =>
            fetch<Methods115['patch']['resBody']>(prefix, `${prefix23}${PATH1}`, PATCH, option).json(),
          $patch: (option: { body: Methods115['patch']['reqBody'], config?: T }) =>
            fetch<Methods115['patch']['resBody']>(prefix, `${prefix23}${PATH1}`, PATCH, option).json().then(r => r.body)
        }
      },
      get: (option?: { query?: Methods114['get']['query'], config?: T }) =>
        fetch<Methods114['get']['resBody']>(prefix, PATH95, GET, option).json(),
      $get: (option?: { query?: Methods114['get']['query'], config?: T }) =>
        fetch<Methods114['get']['resBody']>(prefix, PATH95, GET, option).json().then(r => r.body)
    },
    workers: {
      _id: (val24: string) => {
        const prefix24 = `${PATH96}${val24}`

        return {
          credit_score: {
            patch: (option: { body: Methods118['patch']['reqBody'], config?: T }) =>
              fetch<void>(prefix, `${prefix24}${PATH97}`, PATCH, option).send(),
            $patch: (option: { body: Methods118['patch']['reqBody'], config?: T }) =>
              fetch<void>(prefix, `${prefix24}${PATH97}`, PATCH, option).send().then(r => r.body)
          },
          get: (option?: { config?: T }) =>
            fetch<Methods117['get']['resBody']>(prefix, `${prefix24}${PATH1}`, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods117['get']['resBody']>(prefix, `${prefix24}${PATH1}`, GET, option).json().then(r => r.body),
          patch: (option: { body: Methods117['patch']['reqBody'], config?: T }) =>
            fetch<void>(prefix, `${prefix24}${PATH1}`, PATCH, option).send(),
          $patch: (option: { body: Methods117['patch']['reqBody'], config?: T }) =>
            fetch<void>(prefix, `${prefix24}${PATH1}`, PATCH, option).send().then(r => r.body)
        }
      },
      user_experience: {
        get: (option?: { query?: Methods119['get']['query'], config?: T }) =>
          fetch<Methods119['get']['resBody']>(prefix, PATH98, GET, option).json(),
        $get: (option?: { query?: Methods119['get']['query'], config?: T }) =>
          fetch<Methods119['get']['resBody']>(prefix, PATH98, GET, option).json().then(r => r.body)
      },
      work_count: {
        _id: (val25: string) => {
          const prefix25 = `${PATH99}${val25}`

          return {
            get: (option?: { query?: Methods120['get']['query'], config?: T }) =>
              fetch<Methods120['get']['resBody']>(prefix, `${prefix25}${PATH1}`, GET, option).json(),
            $get: (option?: { query?: Methods120['get']['query'], config?: T }) =>
              fetch<Methods120['get']['resBody']>(prefix, `${prefix25}${PATH1}`, GET, option).json().then(r => r.body)
          }
        }
      },
      get: (option?: { query?: Methods116['get']['query'], config?: T }) =>
        fetch<Methods116['get']['resBody']>(prefix, PATH96, GET, option).json(),
      $get: (option?: { query?: Methods116['get']['query'], config?: T }) =>
        fetch<Methods116['get']['resBody']>(prefix, PATH96, GET, option).json().then(r => r.body)
    }
  }
}

export type ApiInstance = ReturnType<typeof api>
export default api
